
var ignoreStoreSelector=false;

var ua=''+navigator.userAgent;
if (ua.indexOf('APIs-Google')<0 && ua.indexOf('Mediapartners-Google')<0 && ua.indexOf('AdsBot-Google-Mobile')<0 && ua.indexOf('AdsBot-Google')<0 &&
  ua.indexOf('Googlebot-')<0 && ua.indexOf('Googlebot/')<0 && ua.indexOf('AdsBot-Google-Mobile-Apps')<0 && ua.indexOf('bing.com/bingbot')<0 &&
  ua.indexOf('deepcrawl.com/bot')<0 && ua.indexOf('BingBot (')<0 && ua.indexOf('Baiduspider/')<0 && ua.indexOf('Pinterest/')<0 && ua.indexOf('Google Favicon')<0 &&
  ua.indexOf('DuckDuckGo')<0 && ua.indexOf('Yahoo! Slurp')<0 && ua.indexOf('MJ12bot')<0 && ua.indexOf('proximic.com/')<0 && ua.indexOf('Sogou web spider')<0 &&
  ua.indexOf('Slackbot-LinkExpanding')<0 && ua.indexOf('YandexBot/')<0 && ua.indexOf('AhrefsBot/')<0 && ua.indexOf('Twitterbot/')<0 && ua.indexOf('google.com/+/web/snippet')<0 &&
  ua.indexOf('BingPreview/')<0 && ua.indexOf('360Spider(')<0 && ua.indexOf('YodaoBot/')<0 && ua.indexOf('msnbot/')<0 && ua.indexOf('Sosospider+')<0 &&
  ua.indexOf('facebookexternalhit/')<0 && ua.indexOf('Facebot')<0) {
  saveCountry();
  $(document).ready(function() {
    setTimeout(updateStoreSelector, 1000);
  });
}

function saveCountry() {
  if (!$.cookie('slCCodes')) {
    $.getJSON('https://api.ipstack.com/check?access_key=1013a0e5619c2054a66ac712aba870c8&legacy=1', function(data){
      //console.log('data = ',data);
      var stores = {
        "AF": {
          "Name": "Afghanistan",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "AX": {
          "Name": "Aland Islands",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "AL": {
          "Name": "Albania",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "DZ": {
          "Name": "Algeria",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "AS": {
          "Name": "American Samoa",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "AD": {
          "Name": "Andorra",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "AO": {
          "Name": "Angola",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "AI": {
          "Name": "Anguilla",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "AQ": {
          "Name": "Antarctica",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "AG": {
          "Name": "Antigua And Barbuda",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "AR": {
          "Name": "Argentina",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "AM": {
          "Name": "Armenia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "AW": {
          "Name": "Aruba",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "AU": {
          "Name": "Australia",
          "Store": "https:\/\/aus.moanasmileclub.com"
        },
        "AT": {
          "Name": "Austria",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "AZ": {
          "Name": "Azerbaijan",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BS": {
          "Name": "Bahamas",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BH": {
          "Name": "Bahrain",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BD": {
          "Name": "Bangladesh",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BB": {
          "Name": "Barbados",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BY": {
          "Name": "Belarus",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BE": {
          "Name": "Belgium",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BZ": {
          "Name": "Belize",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BJ": {
          "Name": "Benin",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BM": {
          "Name": "Bermuda",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BT": {
          "Name": "Bhutan",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BO": {
          "Name": "Bolivia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BA": {
          "Name": "Bosnia And Herzegovina",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BW": {
          "Name": "Botswana",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BV": {
          "Name": "Bouvet Island",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BR": {
          "Name": "Brazil",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "IO": {
          "Name": "British Indian Ocean Territory",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BN": {
          "Name": "Brunei Darussalam",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BG": {
          "Name": "Bulgaria",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BF": {
          "Name": "Burkina Faso",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BI": {
          "Name": "Burundi",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "KH": {
          "Name": "Cambodia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CM": {
          "Name": "Cameroon",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CA": {
          "Name": "Canada",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CV": {
          "Name": "Cape Verde",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "KY": {
          "Name": "Cayman Islands",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CF": {
          "Name": "Central African Republic",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "TD": {
          "Name": "Chad",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CL": {
          "Name": "Chile",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CN": {
          "Name": "China",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CX": {
          "Name": "Christmas Island",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CC": {
          "Name": "Cocos (Keeling) Islands",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CO": {
          "Name": "Colombia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "KM": {
          "Name": "Comoros",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CG": {
          "Name": "Congo",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CD": {
          "Name": "Congo, Democratic Republic",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CK": {
          "Name": "Cook Islands",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CR": {
          "Name": "Costa Rica",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CI": {
          "Name": "Cote D'Ivoire",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "HR": {
          "Name": "Croatia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CU": {
          "Name": "Cuba",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CY": {
          "Name": "Cyprus",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CZ": {
          "Name": "Czech Republic",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "DK": {
          "Name": "Denmark",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "DJ": {
          "Name": "Djibouti",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "DM": {
          "Name": "Dominica",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "DO": {
          "Name": "Dominican Republic",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "EC": {
          "Name": "Ecuador",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "EG": {
          "Name": "Egypt",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SV": {
          "Name": "El Salvador",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GQ": {
          "Name": "Equatorial Guinea",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "ER": {
          "Name": "Eritrea",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "EE": {
          "Name": "Estonia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "ET": {
          "Name": "Ethiopia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "FK": {
          "Name": "Falkland Islands (Malvinas)",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "FO": {
          "Name": "Faroe Islands",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "FJ": {
          "Name": "Fiji",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "FI": {
          "Name": "Finland",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "FR": {
          "Name": "France",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GF": {
          "Name": "French Guiana",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "PF": {
          "Name": "French Polynesia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "TF": {
          "Name": "French Southern Territories",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GA": {
          "Name": "Gabon",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GM": {
          "Name": "Gambia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GE": {
          "Name": "Georgia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "DE": {
          "Name": "Germany",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GH": {
          "Name": "Ghana",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GI": {
          "Name": "Gibraltar",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GR": {
          "Name": "Greece",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GL": {
          "Name": "Greenland",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GD": {
          "Name": "Grenada",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GP": {
          "Name": "Guadeloupe",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GU": {
          "Name": "Guam",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GT": {
          "Name": "Guatemala",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GG": {
          "Name": "Guernsey",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GN": {
          "Name": "Guinea",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GW": {
          "Name": "Guinea-Bissau",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GY": {
          "Name": "Guyana",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "HT": {
          "Name": "Haiti",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "HM": {
          "Name": "Heard Island & Mcdonald Islands",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "VA": {
          "Name": "Holy See (Vatican City State)",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "HN": {
          "Name": "Honduras",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "HK": {
          "Name": "Hong Kong",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "HU": {
          "Name": "Hungary",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "IS": {
          "Name": "Iceland",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "IN": {
          "Name": "India",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "ID": {
          "Name": "Indonesia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "IR": {
          "Name": "Iran, Islamic Republic Of",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "IQ": {
          "Name": "Iraq",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "IE": {
          "Name": "Ireland",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "IM": {
          "Name": "Isle Of Man",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "IL": {
          "Name": "Israel",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "IT": {
          "Name": "Italy",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "JM": {
          "Name": "Jamaica",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "JP": {
          "Name": "Japan",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "JE": {
          "Name": "Jersey",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "JO": {
          "Name": "Jordan",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "KZ": {
          "Name": "Kazakhstan",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "KE": {
          "Name": "Kenya",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "KI": {
          "Name": "Kiribati",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "KR": {
          "Name": "Korea",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "KW": {
          "Name": "Kuwait",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "KG": {
          "Name": "Kyrgyzstan",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "LA": {
          "Name": "Lao People's Democratic Republic",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "LV": {
          "Name": "Latvia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "LB": {
          "Name": "Lebanon",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "LS": {
          "Name": "Lesotho",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "LR": {
          "Name": "Liberia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "LY": {
          "Name": "Libyan Arab Jamahiriya",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "LI": {
          "Name": "Liechtenstein",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "LT": {
          "Name": "Lithuania",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "LU": {
          "Name": "Luxembourg",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MO": {
          "Name": "Macao",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MK": {
          "Name": "Macedonia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MG": {
          "Name": "Madagascar",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MW": {
          "Name": "Malawi",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MY": {
          "Name": "Malaysia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MV": {
          "Name": "Maldives",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "ML": {
          "Name": "Mali",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MT": {
          "Name": "Malta",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MH": {
          "Name": "Marshall Islands",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MQ": {
          "Name": "Martinique",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MR": {
          "Name": "Mauritania",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MU": {
          "Name": "Mauritius",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "YT": {
          "Name": "Mayotte",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MX": {
          "Name": "Mexico",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "FM": {
          "Name": "Micronesia, Federated States Of",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MD": {
          "Name": "Moldova",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MC": {
          "Name": "Monaco",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MN": {
          "Name": "Mongolia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "ME": {
          "Name": "Montenegro",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MS": {
          "Name": "Montserrat",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MA": {
          "Name": "Morocco",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MZ": {
          "Name": "Mozambique",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MM": {
          "Name": "Myanmar",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "NA": {
          "Name": "Namibia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "NR": {
          "Name": "Nauru",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "NP": {
          "Name": "Nepal",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "NL": {
          "Name": "Netherlands",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "AN": {
          "Name": "Netherlands Antilles",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "NC": {
          "Name": "New Caledonia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "NZ": {
          "Name": "New Zealand",
          "Store": "https:\/\/aus.moanasmileclub.com\/"
        },
        "NI": {
          "Name": "Nicaragua",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "NE": {
          "Name": "Niger",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "NG": {
          "Name": "Nigeria",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "NU": {
          "Name": "Niue",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "NF": {
          "Name": "Norfolk Island",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MP": {
          "Name": "Northern Mariana Islands",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "NO": {
          "Name": "Norway",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "OM": {
          "Name": "Oman",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "PK": {
          "Name": "Pakistan",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "PW": {
          "Name": "Palau",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "PS": {
          "Name": "Palestinian Territory, Occupied",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "PA": {
          "Name": "Panama",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "PG": {
          "Name": "Papua New Guinea",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "PY": {
          "Name": "Paraguay",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "PE": {
          "Name": "Peru",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "PH": {
          "Name": "Philippines",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "PN": {
          "Name": "Pitcairn",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "PL": {
          "Name": "Poland",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "PT": {
          "Name": "Portugal",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "PR": {
          "Name": "Puerto Rico",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "QA": {
          "Name": "Qatar",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "RE": {
          "Name": "Reunion",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "RO": {
          "Name": "Romania",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "RU": {
          "Name": "Russian Federation",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "RW": {
          "Name": "Rwanda",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "BL": {
          "Name": "Saint Barthelemy",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SH": {
          "Name": "Saint Helena",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "KN": {
          "Name": "Saint Kitts And Nevis",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "LC": {
          "Name": "Saint Lucia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "MF": {
          "Name": "Saint Martin",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "PM": {
          "Name": "Saint Pierre And Miquelon",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "VC": {
          "Name": "Saint Vincent And Grenadines",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "WS": {
          "Name": "Samoa",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SM": {
          "Name": "San Marino",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "ST": {
          "Name": "Sao Tome And Principe",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SA": {
          "Name": "Saudi Arabia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SN": {
          "Name": "Senegal",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "RS": {
          "Name": "Serbia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SC": {
          "Name": "Seychelles",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SL": {
          "Name": "Sierra Leone",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SG": {
          "Name": "Singapore",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SK": {
          "Name": "Slovakia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SI": {
          "Name": "Slovenia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SB": {
          "Name": "Solomon Islands",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SO": {
          "Name": "Somalia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "ZA": {
          "Name": "South Africa",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GS": {
          "Name": "South Georgia And Sandwich Isl.",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "ES": {
          "Name": "Spain",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "LK": {
          "Name": "Sri Lanka",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SD": {
          "Name": "Sudan",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SR": {
          "Name": "Suriname",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SJ": {
          "Name": "Svalbard And Jan Mayen",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SZ": {
          "Name": "Swaziland",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SE": {
          "Name": "Sweden",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "CH": {
          "Name": "Switzerland",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "SY": {
          "Name": "Syrian Arab Republic",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "TW": {
          "Name": "Taiwan",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "TJ": {
          "Name": "Tajikistan",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "TZ": {
          "Name": "Tanzania",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "TH": {
          "Name": "Thailand",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "TL": {
          "Name": "Timor-Leste",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "TG": {
          "Name": "Togo",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "TK": {
          "Name": "Tokelau",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "TO": {
          "Name": "Tonga",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "TT": {
          "Name": "Trinidad And Tobago",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "TN": {
          "Name": "Tunisia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "TR": {
          "Name": "Turkey",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "TM": {
          "Name": "Turkmenistan",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "TC": {
          "Name": "Turks And Caicos Islands",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "TV": {
          "Name": "Tuvalu",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "UG": {
          "Name": "Uganda",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "UA": {
          "Name": "Ukraine",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "AE": {
          "Name": "United Arab Emirates",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "GB": {
          "Name": "United Kingdom",
          "Store": "https:\/\/international.moanasmileclub.com"
        },
        "US": {
          "Name": "United States",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "UM": {
          "Name": "United States Outlying Islands",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "UY": {
          "Name": "Uruguay",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "UZ": {
          "Name": "Uzbekistan",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "VU": {
          "Name": "Vanuatu",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "VE": {
          "Name": "Venezuela",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "VN": {
          "Name": "Viet Nam",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "VG": {
          "Name": "Virgin Islands, British",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "VI": {
          "Name": "Virgin Islands, U.S.",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "WF": {
          "Name": "Wallis And Futuna",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "EH": {
          "Name": "Western Sahara",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "YE": {
          "Name": "Yemen",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "ZM": {
          "Name": "Zambia",
          "Store": "https:\/\/moanasmileclub.com\/"
        },
        "ZW": {
          "Name": "Zimbabwe",
          "Store": "https:\/\/moanasmileclub.com\/"
        }
      }

      var countryCode = data.country_code;
      var store;

      if (typeof stores[countryCode] === 'undefined') {
        store = 'https://international.moanasmileclub.com/';
      }
      else {
        store = stores[countryCode]['Store'];
      }

      $.cookie('slCCodes', JSON.stringify({'Country': stores[countryCode]['Name'], 'Code': countryCode, 'Store': store}))
      //console.log('store = ',store,' / countryCode = ',countryCode);

      checkStoreRedirect();
    });

  } else {

    checkStoreRedirect();

  }
}

function getCountry() {
  if ($.cookie('slCCodes')) {
    return JSON.parse($.cookie('slCCodes'));
  }
}

function getParameterByName(name, url) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}


var rdr = getParameterByName('rdr');

if (rdr) {
  $.cookie('dontRedirect', 1);
}

function updateStoreSelector() {

  if (ignoreStoreSelector) {
    return;
  }

  var storeSelectorSpans = $('#storeSelector .countryCode')
  var storeSelectorUrl = $('#storeSelectorUrl');

  var details = getCountry();

  //console.log('details = ',details);
  if (typeof details === 'undefined') {
    // if at this point `details` remains undefined, its due to the AJAX request to the geo server not yet being complete
    // sooooo, run this function again in a second and hope it's there if not rinse and repeat.
    setTimeout(updateStoreSelector, 1000)

    return;
  }

  if (checkStoreRedirect()) {
    return;
  }

  storeSelectorSpans.each(function() {
    $(this).html(details.Country);
  });

  if (details.Store == 'https://international.moanasmileclub.com/') {
    storeSelectorUrl.find('span').html('International');
    $('#storeSelector .countryCode.last').html('International');
  }
  storeSelectorUrl.attr('href', details.Store);

}

function checkStoreRedirect()
{
  var href=''+window.location;
  var details = getCountry();

  if (typeof details === 'undefined') {
    return false;
  }

  console.log("redirecting to.. " + details.Store);

  if (!$.cookie('dontRedirect') && (details.Code != 'AU' && details.Code != 'NZ')) {
    window.location = details.Store;
    ignoreStoreSelector=true;
    return;
  }

  return false;
}
